@import '~styles/_vars.scss';
@import '~styles/_mixins.scss';

.filter {
  margin: 0 auto;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 10px;
  height: 182px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @include gpu;
  will-change: opacity, transform;
  transition: all 500ms cubic-bezier(.51,.41,.16,1.36);
  transform: translateY(-25px);
  opacity: 0;

  .filter__text {
    height: 35px;
    width: 100%;
    max-width: 600px;
    flex: 1;
    margin: 0 1em;
    padding: 0 1em;
    border: 1px solid rgba(0,0,0,.15);
    font-size: 1em;
    font-family: Roboto, sans-serif;
  }

  .filter__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px auto;
  }
  
  .btn {
    cursor: pointer;
    padding: 0 .5em;
    height: 35px;
    margin: 0 .25em;
    min-width: 80px;
    color: $blue;
    border: 1px solid $blue;
    background-color: transparent; 
    &:hover {
      background: white;
      color: $blueDark;
    }
    &.active{      
      color: white;
      background-color: $blue;
      border: 1px solid transparent;
      &:hover {
        background-color: $blueDark;
      }
    }   
    &:focus { outline: none; }
  }
}