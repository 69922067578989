$bg: #f5f5f5;
$paragraph: #2a2924;
$heading: #3a3934;
$textShadow: rgba(0, 0, 0, 0.25);

$red: #f0427c;
$redDark: #e0326c;
$yellow: #f8b73e;
$yellowDark: #e8a72e;
$green: #40bf80;
$greenDark: #30af70;
$blue: #33b2cc;
$blueDark: #23a2bc;
$purple: #bc79ec;
$purpleDark: #ac69dc;
$gray: #3a3934;
$grayDark: #2a2924;
