@import "_vars.scss";

body {
  color: $gray;

  a {
    font-weight: 700;
    color: #111;
  }
  a:visited {
    color: #111;
  }
  a:hover {
    text-decoration: none;
  }
  p.centered {
    text-align: center;
  }

  .content {
    position: relative;
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    section {
      padding: 0;
    }
  }

  button {
    box-sizing: content-box;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    user-select: none;
    &:focus {
      outline: none;
    }
  }

  section {
    position: relative;
  }

  ::selection {
    background: $yellow;
  }
  ::-moz-selection {
    background: $yellow;
  }
}

@media (min-width: 535px) {
  section {
    padding: 6rem 2rem;
  }
  section.welcome .welcome__title {
    font-size: 3.5em;
  }
}

@media (min-width: 980px) {
  section {
    padding: 6rem 0 3rem 0;
  }
  .welcome .welcome__title {
    font-size: 4em;
  }
}

@media (min-width: 395px) {
  section.welcome .welcome__title {
    font-size: 2.5em;
  }
}

@media (min-width: 700px) {
  section.welcome .welcome__title {
    font-size: 3.75em;
  }
}
