@import '~styles/_vars.scss';
@import '~styles/_mixins.scss';

.projectLink {
  position: relative;
  background-size: auto;
  background-repeat: no-repeat; 
  background-position: 50% 50%;
  text-decoration: none;
  overflow: hidden;
  will-change: background-image, transform, opacity;

  &:before {
    content: "TBD";
    transform: rotate(45deg);
    transform-origin: 0 0;
    background-color: #ccc;
    color: rgb(255,255,255);
    line-height: 1.5em;
    font-weight: 700;
    letter-spacing: .1em;
    position: absolute;
    right: -3.3em;
    text-align: center;
    top: -1.1em;
    transform: rotate(45deg);
    transform-origin: 0 0;
    width: 7em;
    height: 1.5em;
    z-index: 1;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.25);
  }

  & .projectLink__overlay {
    @include gpu;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.35);
    transition: opacity 150ms ease-out;
    will-change: opacity;
  }

&.JavaScript:before { 
  content: "JS";
  background-color: $yellow; 
}
&.C\#:before { 
  content: "C#";
  background-color: $purple;
}
&.HTML:before {
  content: "HTML";
  background-color: $red;
}
&.PHP:before {
  content: "PHP";
  background-color: $blue;
}

&.SCRAPPED:before { 
  content: "N/A SCRAPPED";
  background-color: $yellow; 
}
&.NA:before { 
  content: "NOT AVAILABLE";
  background-color: $red; 
}
&.TBD:before {
  background-color: $purple;
  content: "TO BE DETERMINED"; 
}
&.INPROGRESS:before { 
  background-color: $blue;
  content: "IN PROGRESS"; 
}
&.DONE:before { 
  background-color: $green;
  content: "100% FINISHED"; 
}

  &.loading {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border: 3px solid #eee;
      color: #ccc;
      content: 'Loading';
      text-align: center;
      z-index: 10;
    }
  }

  &:hover {
    h3, h4 { transform: translateY(-1.5em); }
    h4 { transition-delay: 75ms; }
    & .projectLink__overlay {
      opacity: 1;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2em 1em;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    text-decoration: none;   
    text-shadow: 0 0 10px rgba(0,0,0,.5); 
  }

  h3, h4 { 
    @include gpu;
    cursor: pointer;
    color: white; 
    text-transform: uppercase;
    transition: transform 150ms cubic-bezier(.51,.41,.16,1.36);
    transition-delay: 0ms;
  }
  h3 { font-weight: bold; }

  &:after {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0,0,0,.125), rgba(0,0,0,.5));
  }
  
}

@keyframes fadeIn {
  from { transform: translateY(75px) scale(0.75); opacity: 0; }
  to { transform: none; opacity: 1; }
}

@media (min-width: 1024px) {@import '~styles/_mixins.scss';

  .projectLink {
    transform: translateY(75px) scale(0.75); 
    opacity: 0;
    animation: fadeIn 200ms cubic-bezier(.51,.41,.16,1.36) forwards;
  }
}