.project {
  background: #111;
  padding-top: 100px;
  .content {
    min-height: 50vh;
  }
}

.grid-test {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  .grid-test-item {
    display: inline-block;
    width: 386px;
    height: 380px;
    margin: 5px;
    border-radius: 4px;
    background: rgba(255,255,255,.125);
    border-top: 2px solid rgba(255,255,255,.05);
    
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 300px;
    font-weight: 700;
    color: #1a1a1a;
    text-shadow: 0 -1px 2px rgba(255,255,255,.05);
  }
}

.list-test {
  display: flex;
  flex-wrap: wrap;
  background: #f0f0f0;
  padding: 50px 0;
  .list-test-item {
    padding: 20px;
    width: 290px;
    height: 250px;
    margin: 8px auto;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    list-style: none;
    transform: translateY(100px); 
    opacity: 0;
    border-radius: 4px;
    animation: slide-up 1s forwards;
    animation-delay: 0ms;
  }
}
@keyframes slide-up {
  from { transform: translateY(100px); opacity: 0; }
  to { transform: none; opacity: 1; }
}

